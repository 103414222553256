exports.components = {
  "component---src-components-collections-collection-js": () => import("./../../../src/components/collections/collection.js" /* webpackChunkName: "component---src-components-collections-collection-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie-mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kolekcijos-js": () => import("./../../../src/pages/kolekcijos.js" /* webpackChunkName: "component---src-pages-kolekcijos-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-pirkimo-taisykles-js": () => import("./../../../src/pages/pirkimo-taisykles.js" /* webpackChunkName: "component---src-pages-pirkimo-taisykles-js" */),
  "component---src-pages-pristatymas-js": () => import("./../../../src/pages/pristatymas.js" /* webpackChunkName: "component---src-pages-pristatymas-js" */),
  "component---src-pages-privatumo-politika-js": () => import("./../../../src/pages/privatumo-politika.js" /* webpackChunkName: "component---src-pages-privatumo-politika-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */)
}

